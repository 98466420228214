const N2WIndian = (() => {
  const zeroTo99 = [];
  const zeroTo9 = [
    'Zero', 'One', 'Two', 'Three', 'Four',
    'Five', 'Six', 'Seven', 'Eight', 'Nine'
  ];
  const place = 'Thousand|Lakh|Crore|Arab|Kharab|Nil'.split('|');

  const initialize = () => {
    const ones =
      '|One|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen'.split(
        '|'
      );
    const tens = '||Twenty|Thirty|Forty|Fifty|Sixty|Seventy|Eighty|Ninety'.split('|');

    for (let i = 0; i < 100; i++) {
      const t = Math.floor(i / 10);
      const o = i % 10;
      zeroTo99.push(t < 2 ? ones[i] : tens[t] + (o ? ' ' + ones[o] : ''));
    }
  };

  const convertFirstThreeDigitsToWords = (numberStr) => {
    return numberStr.slice(0,3)
    .split('')
    .map(digit => zeroTo9[parseInt(digit)])
    .join(' ');
  };
  
  const convert = (x) => {
    let n = x.length;
    x = n === 0 ? '00' : n === 1 || n % 2 === 0 ? '0' + x : x;
    n = x.length;
    let r = zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
    if (n >= 1) {
      const v = zeroTo99[x.charCodeAt((n -= 1)) - 48];
      if (v) r = v + ' Hundred' + (r ? ' ' + r : '');
    }
    for (let i = 0; n > 0; i++) {
      const v = zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
      if (v) r = v + ' ' + place[i] + (r ? ' ' + r : '');
    }
    return r || '';
  };

  initialize();

  return {
    convertFirstThreeDigitsToWords,
    convert,
    initialize,
  };
})();

const handleNumberString = (inputStr) => {
  if (inputStr.includes('.')) {
    const [whole, decimal] = inputStr.split('.');
    return {
      type: 'decimal',
      original: inputStr,
      beforeDecimal: N2WIndian.convert(whole),
      afterDecimal: N2WIndian.convertFirstThreeDigitsToWords(decimal)
    };
  } else {
    return {
      type: 'integer',
      original: inputStr,
      converted:  N2WIndian.convert(inputStr)
    };
  }
};

export const numberToWords = (num) => {
  if (isNaN(num) || num === '') return ''; 
  const stringNumber = num.toString();
  const result = handleNumberString(stringNumber);
  if(result.type === 'decimal'){
    return result.beforeDecimal + ' Rupees ' + result.afterDecimal + ' Paisa';
  }
  else return result.converted + ' Rupees';
};
